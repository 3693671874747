body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	overflow-y: hidden;
	overflow-x: hidden;
}

amp-img {
	filter: brightness(70%);
}
amp-story-cta-layer a {
	background-color: #fff;
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
	color: rgb(10, 10, 10);
	width: "auto";
	position: absolute;
	padding: 0.5rem;
	border-radius: 4px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-decoration: none;
	font-weight: bolder;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	text-align: center;
}
amp-story-cta-layer a:visited {
	color: rgb(10, 10, 10);
}
amp-story-cta-layer a:hover {
	text-decoration: none;
	color: rgb(10, 10, 10);
}
